<template>
  <div
    v-if="show"
    :ref="to"
    :style="{background, color}"
    class="ra-card"
    v-on="$listeners"
  >
    <div class="ra-card__text">
      <h2 v-text="title" />
      <p v-text="subtitle" />
      <RouterLink :to="{name: to}">
        <RaButton
          size="medium"
          :text="button"
          :type="buttonType"
        />
      </RouterLink>
    </div>
    <div class="ra-card__image">
      <div
        v-if="!showImage"
        class="ra-skeleton"
        :style="{
          background: `linear-gradient(127deg, ${background}, ${color} 70.71%)`
        }"
      />
      <img
        v-if="showImage"
        :src="image"
        :alt="title"
      />
    </div>
  </div>
</template>

<script>
import RaButton from '@/components/elements/RaButton.vue'

export default {
  name: 'NewsCard',

  components: {
    RaButton
  },

  props: {
    show: {
      type: Boolean
    },

    title: {
      type: String,
      default: () => 'Is that a title?'
    },

    subtitle: {
      type: String,
      default: () => 'Woow'
    },

    to: {
      type: String,
      default: () => 'blog'
    },

    button: {
      type: String,
      default: () => 'Check this out'
    },

    buttonType: {
      type: String,
      default: () => ''
    },

    image: {
      type: String,
      default: () => '/hub/panda.png'
    },

    background: {
      type: String,
      default: () => '#323da5'
    },

    color: {
      type: String,
      default: () => '#e3f2fd'
    }
  },

  data: () => ({
    showImage: false
  }),

  mounted() {
    this.observeImage()
  },

  methods: {
    observeImage() {
      const callback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const target = this.$refs[this.to]
            setTimeout(() => {
              this.showImage = true
            }, 600)
            if (target) observer.unobserve(target)
          }
        })
      }

      const observer = new IntersectionObserver(callback, {
        rootMargin: '0px',
        threshold: 0.3
      })

      const target = this.$refs[this.to]
      if (target) observer.observe(target)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/scss/variables.scss';

  .ra-card {
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.2s;
    margin-top: 16px;
    &__text {
      padding: 30px;
      p {
        font-size: 18px;
        line-height: 1.5;
      }
      a {
        margin: 25px 0 0;
      }
    }
    &__image {
      min-height: 320px;
      transition: all 1s ease-out;
    }
    img {
      width: 100%;
      max-height: 300px;
      object-fit: contain;
      margin-bottom: -7px;
    }
    &:hover {
      box-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.4) inset;
    }
  }

  @media (min-width: 768px) {
    .ra-card {
      display: grid;
      grid: auto / 1fr 250px;
      &__image {
        align-self: flex-end;
        padding: 15px 0 0;
      }
    }
  }

  .ra-skeleton {
    height: 300px;
    width: 300px;
    background-color: $grayShade4;
    border-radius: 50%;
  }
</style>
