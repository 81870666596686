<template>
  <div class="ra-basic ra-basic-text">
    <h2>
      Blog
    </h2>
    <RaTagItem
      v-for="(tag, k) in tags"
      :key="`${tag.id}-${k}`"
      :name="tag.name"
      :active="activeTag === tag.id"
      show
      @click="selectTag(tag.id)"
    />
    <NewsCard
      v-for="(post, key) in articles"
      :key="key"
      :show="post.show"
      :title="post.title"
      :subtitle="post.subtitle"
      :to="post.to"
      :button="post.button"
      :button-type="post.buttonType"
      :image="post.image"
      :background="post.background"
      :color="post.color"
      @click="$router.push({name: post.to})"
    />
  </div>
</template>

<script>
import VTitle from 'v-title'

import NewsCard from '@/components/blocks/NewsCard.vue'
import RaTagItem from '@/components/elements/RaTagItem.vue'

import articles from '@/data/articles'

export default {
  name: 'Blog',

  components: {
    NewsCard,
    RaTagItem
  },

  directives: {
    title: VTitle
  },

  metaInfo: {
    title: 'Blog',
    titleTemplate: '%s | Roman Almazov',

    meta: [
      {
        name: 'description',
        content: 'My blog about frontend, management etc.'
      },
      { name: 'og:title', content: 'Blog | Roman Almazov' },
      { name: 'twitter:title', content: 'Blog | Roman Almazov' },
      {
        name: 'og:description',
        content: 'My blog about frontend, management etc.'
      },
      {
        name: 'twitter:description',
        content: 'My blog about frontend, management etc.'
      },
      { name: 'og:url', content: 'https://ralmaz.pro/blog' },
      { name: 'twitter:url', content: 'https://ralmaz.pro/blog' }
    ]
  },

  data: () => ({
    articles: [],

    tags: [
      {
        id: 0,
        name: 'All'
      },
      {
        id: 1,
        name: 'Vue 2.x'
      },
      {
        id: 2,
        name: 'Tailwind'
      }
    ],

    activeTag: 0
  }),

  mounted() {
    this.selectTag(0)
  },

  methods: {
    selectTag(tagId) {
      let result = articles[0].id > articles[1].id
        ? articles
        : articles.reverse()

      if (tagId == 0) {
        this.articles = result
      } else {
        result = result.filter((f) => f.tag === tagId)
        this.articles = result
      }

      this.activeTag = tagId
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/scss/variables.scss';

  .ra-small {
    font-size: 15px;
    color: $primaryColor;
    &-accent {
      font-size: 17px;
      font-weight: 900;
      margin-left: 5px;
    }
  }

  .ra-body-light {
    .ra-small {
      color: $black;
    }
  }
</style>
