export default [
  {
    id: 1,
    tag: 1,
    show: true,
    title: 'When "default" in Vue property - can be inadequacy',
    subtitle: 'and why its wrong in documentation?',
    to: 'default-property',
    button: 'Check this out!',
    buttonType: 'warning',
    image: '/hub/eagle.png',
    background: '#323da5',
    color: 'white'
  },
  {
    id: 2,
    tag: 1,
    show: true,
    title: 'Right way ordering things inside a component',
    subtitle: 'Options, assets, composition etc',
    to: 'ordering',
    button: 'Open list',
    buttonType: 'secondary',
    image: '/hub/panda.png',
    background: '#ffc21a',
    color: 'black'
  },
  {
    id: 3,
    tag: 1,
    show: true,
    title: 'The watcher is bad, deep watcher - memory hell',
    subtitle: 'Still in doubt? Read',
    to: 'watcher',
    button: 'Read asap',
    buttonType: 'primary',
    image: '/hub/owl.png',
    background: '#6410d4',
    color: 'white'
  },
  {
    id: 4,
    tag: 1,
    show: true,
    title: 'Mixins - elegant work with API',
    subtitle: 'And why you no longer need Vuex.actions',
    to: 'mixins',
    button: 'Explore',
    buttonType: 'primary',
    image: '/hub/lion.png',
    background: '#0072ff',
    color: 'white'
  },
  {
    id: 5,
    tag: 2,
    show: true,
    title: 'Tailwind presents itself completely wrong',
    subtitle: 'That is why many still do not understand what opportunities Tailwind gives',
    to: 'tailwind',
    button: 'Read',
    buttonType: 'secondary',
    image: '/hub/jumpcat.png',
    background: '#edacff',
    color: 'black'
  }
]
